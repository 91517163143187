<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="8" :xs="22" :sm="22" :md="18" :lg="12" :xl="8" :xxl="8">
        <div style="text-align:center"><Logo /></div>
        <div v-if="currentCounter === -1">
          <div style="text-align: center">
            <h1 style="text-align: center">Start Survey</h1>
            <br />
            <h2 style="text-align: center">Are you a guest user or known user?</h2>
            <br />
            <a-radio-group v-model="userType" size="large">
              <a-radio value="guest">
                Guest User
              </a-radio>

              <a-radio value="knownuser">
                Known user
              </a-radio>
            </a-radio-group>
            <div style="text-align:center">
              <vue-recaptcha @verify="onCaptchVerify" sitekey="6Lc31VQbAAAAAJUkP3TLRTGXszq0lUrjFK8Ni-IC" :loadRecaptchaScript="true" style="text-align:center;margin: auto;margin-top: 50px;" class="captcha-container"></vue-recaptcha>
            </div>
            <div style="margin-top:50px;text-align:center;">
              <a-button icon="play-circle" type="primary" size="large" style="width: 300px; background: #5168ea; border-color: #5168ea" @click="increment">
                Start Survey
              </a-button>
            </div>
          </div>
        </div>
        <div v-if="currentCounter === 0">
          <div v-html="welcomeText"></div>
          <a-col :span="12" :offset="6">
          <div style="text-align:center">
            <a-button
              size="large"
              type="primary"
              @click="increment"
              class="login-form-button"
              style="width: 100%; background: #5168ea; border-color: #5168ea"
            >
              <a-icon type="lock" style="color: white" />
              <span style="color: white" v-if="whileSaving">Saving...</span>
              <span style="color: white" v-if="!whileSaving">Proceed</span>
            </a-button>
          </div>
          </a-col>
        </div>
        <div v-if="currentCounter === 1">
          <div v-if="userType === 'guest'" style="text-align:center;">
            <h2>Welcome Guest</h2>
            <p>Please provide your details</p>
            <a-row type="flex" justify="center">
            <a-col :span="18" style="text-align:center">
            <div style="text-align:center">
              <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmit"
              >
                <a-row>
                    <a-col :span="12">
                      <a-form-item>
                        <a-input
                          :disabled="whileSaving"
                          v-decorator="['firstName', {
                              rules: [
                                { required: true, message: 'Please input First Name!' },
                              ],
                            },]"
                          size="large"
                          placeholder="First Name*"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="11" :offset="1">
                      <a-form-item>
                        <a-input
                          :disabled="whileSaving"
                          v-decorator="['lastName', {
                              rules: [
                                { required: true, message: 'Please input Last Name!' },
                              ],
                            },]"
                          size="large"
                          placeholder="Last Name*"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                </a-row>
                <a-form-item>
                  <a-select :disabled="whileSaving" v-decorator="['gender', {
                        rules: [
                          { required: true, message: 'Please input Gender!' },
                        ],
                      },]" size="large" placeholder="Gender*">
                    <a-select-option value="Male">
                      Male
                    </a-select-option>
                    <a-select-option value="Female">
                      Female
                    </a-select-option>
                    <a-select-option value="Other">
                      Other
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-input
                    :disabled="whileSaving"
                    v-decorator="['email']"
                    size="large"
                    placeholder="Email"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                    :disabled="whileSaving"
                    v-decorator="['contactNumber']"
                    size="large"
                    placeholder="Contact Number"
                  >
                  </a-input>
                </a-form-item>
                <!-- <a-form-item>
                  <a-date-picker
                    :disabled="whileSaving"
                    size="large"
                    style="width: 100%"
                    v-decorator="[
                      'dob',
                    ]"
                    placeholder="Date Of Birth"
                  >
                  </a-date-picker>
                </a-form-item> -->
                <a-form-item>
                  <a-input
                    :disabled="whileSaving"
                    size="large"
                    v-decorator="[
                      'address',
                    ]"
                    placeholder="Steet Address"
                  >
                  </a-input>
                </a-form-item>
                <a-row>
                    <a-col :span="8">
                        <a-form-item>
                            <a-input
                                :disabled="whileSaving"
                                size="large"
                                v-decorator="['city']"
                                placeholder="City"
                            >
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="7" :offset="1">
                        <a-form-item>
                            <a-input
                                :disabled="whileSaving"
                                v-decorator="['state']"
                                size="large"
                                placeholder="State"
                            >
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="7" :offset="1">
                        <a-form-item>
                            <a-input
                                :disabled="whileSaving"
                                v-decorator="['zipcode']"
                                size="large"
                                placeholder="Zip Code"
                            >
                            </a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-form-item>
                  <a-button
                    size="large"
                    type="primary"
                    html-type="submit"
                    class="login-form-button"
                    style="width: 100%; background: #5168ea; border-color: #5168ea"
                  >
                    <a-icon type="lock" style="color: white" />
                    <span style="color: white" v-if="whileSaving">Saving...</span>
                    <span style="color: white" v-if="!whileSaving">Next</span>
                  </a-button>
                </a-form-item>
              </a-form>

            </div>
            </a-col>
            </a-row>
          </div>
          <div v-if="userType === 'knownuser'">
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleKnownUserSubmit"
              >
                <a-row type="flex" justify="center">
                    <a-col :xs="24" :sm="22" :md="18" :lg="18" :xl="18" :xxl="18" >
                      <h3>Please enter your User Id</h3>
                      <br />
                      <a-form-item>
                        <a-input
                          :disabled="whileSaving"
                          v-decorator="['screen2passId', {
                              rules: [
                                { required: true, message: 'Please input User Id!' },
                              ],
                            },]"
                          size="large"
                          placeholder="User Id*"
                        >
                        </a-input>
                      </a-form-item>
                      <a-form-item>
                        <a-button
                          size="large"
                          type="primary"
                          html-type="submit"
                          class="login-form-button"
                          style="width: 100%; background: #5168ea; border-color: #5168ea"
                        >
                          <a-icon type="lock" style="color: white" />
                          <span style="color: white" v-if="whileSaving">Checking...</span>
                          <span style="color: white" v-if="!whileSaving">Next</span>
                        </a-button>
                      </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
          </div>
        </div>
        <div v-if="(currentCounter === 2 && userType === 'knownuser')">
          <a-row type="flex" justify="center">
            <a-col :span="24" style="font-size: 1.1rem;">
              <table class="subscriber-table" style="border: 1px solid #d3d3d3;width: 100%;">
                <tbody>
                  <tr>
                    <td>First Name</td>
                    <td>{{ subscriber.firstName }}</td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>{{ subscriber.lastName }}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{{ subscriber.gender }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ subscriber.email }}</td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>{{ subscriber.contactNumber }}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {{ subscriber.address }}<br />
                      {{ subscriber.city }}<br />
                      {{ subscriber.state }}<br />
                      {{ subscriber.zipcode }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <a-row>
                <a-col :span="11">
                  <a-button
                    size="large"
                    type="danger"
                    class="login-form-button"
                    style="width: 100%;"
                    @click="currentCounter--"
                  >
                    <a-icon type="close-circle" style="color: white" />
                    <span style="color: white" v-if="whileSaving">Saving...</span>
                    <span style="color: white" v-if="!whileSaving">Cancel</span>
                  </a-button>
                </a-col>
                <a-col :span="11" :offset="2">
                  <a-button
                    size="large"
                    type="primary"
                    class="login-form-button"
                    @click="currentCounter++"
                    style="width: 100%; background: #5168ea; border-color: #5168ea"
                  >
                    <a-icon type="check-circle" style="color: white" />
                    <span style="color: white" v-if="whileSaving">Saving...</span>
                    <span style="color: white" v-if="!whileSaving">Confirm & Proceed</span>
                  </a-button>
                </a-col>
              </a-row>
              <br />
              <br />
              <br />
              <br />
            </a-col>
          </a-row>
        </div>
        <div v-if="(currentCounter === 2 && userType === 'guest') || (currentCounter === 3 && userType === 'knownuser')">
          <div v-if="failed">
            <div v-html="failureText"></div>
          </div>
          <div v-if="success">
            <div v-html="successText"></div>
            <div style="text-align:center">
              <h2 style="color:green">Valid till</h2>
              <h2>{{ new Date() | dateFormat('DD') }}</h2>
              <h3>{{ new Date() | dateFormat('MMM YYYY') }}</h3>
              <h4>{{ new Date() | dateFormat('23:59:59') }}</h4>
            </div>
          </div>
          <div v-if="!success && !failed">
            <div v-for="(item, index) in questions" v-bind:key="item.id">
              <div v-if="questionCounter === index">
                <div v-if="questions.length > 0" v-html="item.name"></div>
                <br />
                <div>
                  <a-button
                    size="large"
                    class="add-btn"
                    type="primary"
                    @click="
                      savePreviewResponse(item.questionId, item.options[0]);
                    "
                  >
                    Yes
                  </a-button>
                  <a-button
                    size="large"
                    style="margin-left: 20px"
                    class="add-btn"
                    type="danger"
                    @click="
                      savePreviewResponse(item.questionId, item.options[1]);
                    "
                  >
                    No
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {v4 as uuid } from 'uuid';
import { SurveyApi, SurveyQuestionApi, SurveyResultApi, SubscriberApi } from "../../services";
import Logo from "../../components/Logo.vue";
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    Logo,
    VueRecaptcha
  },
  data() {
    return {
      attempt: uuid(),
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      captchaVerified: false,
      captchaToken: null,
      currentCounter: -1,
      questionCounter: 0,
      userType: 'guest',
      questions: [],
      success: false,
      failed: false,
      welcomeText: ``,
      failureText: ``,
      successText: ``,
      subscriberId: null,
      screen2passId: null,
      whileSaving: false,
      subscriber: {
        firstName: null,
        lastName: null,
        email: null,
        dob: null,
        gender: null,
        address: null,
        city: null,
        zipcode: null,
        state: null,
        contactNumber: null,
        referenceId1: null,
        referenceId2: null
      }
    }
  },
  methods: {
    onCaptchVerify(value) {
      console.log(value);
      this.captchaVerified = true;
      this.captchaToken = value;
    },
    async getSurveyDetails() {
      const { surveyid } = this.$route.params;
      const response = await SurveyQuestionApi.list(surveyid);
      this.questions = response.data;
      const results = await SurveyApi.retrieve(surveyid);
      try {
        this.surveyInfo = results.data[0];
        this.welcomeText = this.surveyInfo.welcomeText;
        this.successText = this.surveyInfo.successText;
        this.failureText = this.surveyInfo.failureText;
      } catch (err) {
        console.log(err);
      }
    },
    async getSubscriberDetails() {
      const { locationid } = this.$route.params;
      try {
        const response = await SubscriberApi.checkIfUserIdExists({
          screen2passId: this.screen2passId,
          locationId: locationid
        })
        this.subscriberId = response.data.subscriberId;
        this.subscriber = response.data;
        this.increment();
      } catch(err) {
        this.$message.error("Invalid User Id provided or user is disabled");
      }
    },
    beforeCreate() {
      this.form = this.$form.createForm(this, { name: "normal_login" });
    },
    async increment() {
      if (this.currentCounter === -1) {
        if (!this.captchaVerified) {
          this.$message.error("Please verify the Captcha to proceed");
          return;
        }
      }
      this.currentCounter++;
      if (this.currentCounter === 1) {
        this.form = this.$form.createForm(this, { name: "normal_login" });
      }
      if (this.currentCounter === 0) {
          await this.getSurveyDetails();
      }
    },
    decrement() {
      this.currentCounter--;
    },

    hasCustomQuestionsLeft() {
      let found = false;
      let indexAt = null;
      for (let index = this.currentCounter; index < this.questions.length;index++) {
        if (this.questions[index].type === 'custom') {
          found = true;
          indexAt = index;
          break;
        }
      }
      return {found, indexAt};
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await this.increment();
          // save guest user
          this.whileSaving = true;
          const response = await SubscriberApi.saveGuestSubscriber(values);
          this.subscriberId = response.data[0].subscriberId;
          this.whileSaving = false;
        }
      });
    },
    async handleKnownUserSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log(values);
          this.screen2passId = values.screen2passId;
          await this.getSubscriberDetails();
        }
      })
    },
    async completeSurvey(passed) {
      const payload = {
          surveyId: this.$route.params.surveyid,
          subscriberId: this.subscriberId,
          screen2passId: this.screen2passId,
          locationId: this.$route.params.locationid,
          passed: passed,
          attempt: this.attempt
        }
        await SurveyResultApi.create(payload);
    },
    async savePreviewResponse(questionId, value) {
      const item = this.questions.filter(
        (record) => record.questionId === questionId
      );

      let payload = {
        surveyId: this.$route.params.surveyid,
        subscriberId: this.subscriberId,
        questionId: item[0].questionId,
        globalQuestionId: item[0].globalQuestionId,
        locationId: this.$route.params.locationid,
        answer: value,
        correct: item[0].answer === value,
        timeTaken: 1000,
        attempt: this.attempt,
        completeSurvey: item[0].completeSurvey
      }

      await SurveyQuestionApi.saveAnswer(payload);

      if (!item[0].completeSurvey && item[0].answer !== value) {
        this.failed = true;
        this.success = false;
        await this.completeSurvey(false);
        return;
      }

      if (item[0].completeSurvey && item[0].answer === value) {
        if (item[0].type === 'custom') {
          this.failed = false;
          this.success = true;
          await this.completeSurvey(true);
          return;
        } else {
          const customQuestions = this.hasCustomQuestionsLeft();
          if (!customQuestions.found) {
            this.failed = false;
            this.success = true;
            await this.completeSurvey(true);
            return;
          } else {
            this.questionCounter = customQuestions.indexAt - 1;
          }
        }
      }
      this.questionCounter++;
      if (this.questionCounter === this.questions.length) {
        if (item[0].answer === value) {
          this.success = true;
          // Save Success Result
          await this.completeSurvey(true);
        }
      }
    }
  },
};
</script>
<style scoped>
.subscriber-table tr td {
  width: 50%;
  padding: 15px;
  font-weight: 600;
  border: 1px solid #d3d3d3;
}
.subscriber-table tr td:first-child {
  background: #f5f5f5;
}
.captcha-container >>> div {
  margin: auto;
}
</style>
