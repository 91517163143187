<template>
  <div>
    <h2>Survey </h2>
  </div>
</template>
<script>
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const LocationModule = namespace("LocationModule");
import { Location } from "../../store/modules/location/types";
import { SurveyApi } from "../../services";

export default {
  methods: {
    
  },
};
</script>
<style scoped>

</style>